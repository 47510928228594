import { useEffect } from 'react';

function About() {

  useEffect(() => {
    document.title = 'OTCShortReport | About Us';
    document.getElementsByTagName('meta')['keywords'].content = 'OTC SHORT POSITION REPORT, OTC SHORT REPORT, OTC STOCK DATA, PINKSHEET SHORT POSITION, MARKET MAKERS SHORTING STOCK, OTCQB SHORT SELLING, PENNY STOCK SHORTING, stocks, quotes, finance';
    document.getElementsByTagName('meta')['description'].content = 'About us';
  }, []);

  return (
    <>
      <div className="container">
        <div className="midcontent">
          <div className="leftc leftcp">
              <div className="namec">
                  <div className="namet">About Us</div>
                  <div className="divider"></div>
                  <div className="para">OtcShortReport.com was put together for one reason. To track the true volume, and display in a coherent fashion the daily short positions of over the counter stocks. Short Positions for over the counter (OTC) stocks are hard to come by. At OtcShortReport.com we make digesting the information easy to understand, and add valuable charting and historical data. This saves you time, and most importantly it is all free!</div>
                  <div className="para">We hope to help investors with understanding what is really happening with your OTC investments.</div>
                  <div className="para">Believe it or not, most CEO's are not even aware that they are a victim of manipulative short selling!</div>
                  <div className="para">We always look forward to comments and suggestions. If there are any features you would like to see, or anything we can help with making your experience a better one, please don't hesitate to contact us.</div>
                  <div className="para">OtcShortReport.com "Free Daily Short Position Reports on all your OTC Investments"</div>
              </div>
              <br /><br /><br /><br /><br />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;