import { Link } from "react-router-dom";

function Footer() {
    return (
      <>
        <div className="footerc">
            <div className="container">
                <div className="footermenuc">
                    <div className="fmi fmifirst"><Link to="/about">About OTCShortReport.com</Link></div>
                    <div className="fmi"><Link to="/faq">FAQ</Link></div>
                    <div className="fmi"><Link to="/privacy-policy">Privacy Policy</Link></div>
                    <div className="fmi"><Link to="/disclaimer">Disclaimer</Link></div>
                </div>
                <div className="disclaimer">
                    Most stock quote data provided by Yahoo. Market indices are are delayed by fifteen minutes. All times are ET. Disclaimer. Harris Market Consulting.: © 2021 Harris Market Consulting. All Rights Reserved. FINRA:REGSHO data provided by FINRA. All rights reserved. Chicago Mercantile Association: Certain market data is the property of Chicago Mercantile Exchange Inc. and its licensors. All rights reserved. Dow Jones: The Dow Jones branded indices are proprietary to and are calculated, distributed and marketed by DJI Opco, a subsidiary of S&amp;P Dow Jones Indices LLC and have been licensed for use to S&amp;P Opco, LLC and Yahoo. Standard &amp; Poor's and S&amp;P are registered trademarks of Standard &amp; Poor's Financial Services LLC and Dow Jones is a registered trademark of Dow Jones Trademark Holdings LLC. All content of the Dow Jones branded indices © S&amp;P Dow Jones Indices LLC 2015 and/or its affiliates. All short data algorithms are property of Harris Market Consulting.</div>
                <div className="disclaimer">
                    2021 OtcShortReport.com An Harris Market Consulting. Company. All Rights Reserved. Terms under which this service is provided to you. Please read our disclosure and privacy policies.</div>
            </div>
        </div>
      </>
    );
}
  
export default Footer;