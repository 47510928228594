import { useEffect, useState, useRef } from "react";


function Header() {

    const [mostShorted, setMostShorted] = useState([]);
    const searchRef = useRef(null);
    
    useEffect(() => {
        
        /**Check if mostShorted Local storage array is set**/
        if(localStorage.getItem("mostShortedArr") === null) {
            localStorage.setItem("mostShortedArr", '');
        }

        /** Check if Cache Time is set **/
        if(localStorage.getItem("mostShortedStocksTime") === null) {
            localStorage.setItem("mostShortedStocksTime", Math.round(new Date().getTime() / 1000));
        }

        async function loadMostShorted() {
            try {
                const mSResponse = await fetch('/ajax.php?action=mostshorted');
                const mJsonObj = await mSResponse.json();
                localStorage.setItem("mostShortedArr", mJsonObj);
                localStorage.setItem("mostShortedStocksTime", Math.round(new Date().getTime() / 1000));
                setMostShorted(mJsonObj);
            }
            catch(e) {
                console.log(e);
            }
        }

        /** Set New Cache Time if difference is greater thatn 10 mins**/
        if(Math.round(new Date().getTime() / 1000) - localStorage.getItem("mostShortedStocksTime") >= 600) {
            loadMostShorted();
        }
        else if(localStorage.getItem("mostShortedArr").length > 0){
            setMostShorted(localStorage.getItem("mostShortedArr").split(','));
        }
        else {
            loadMostShorted();
        }
    }, []);

    function handleSubmit(e) {
        e.preventDefault();
        let searchVal = searchRef.current.value.toUpperCase().replace(/\s/g, "");
        searchVal = searchVal.substr(0, 10);
        if(searchVal.length > 0) {
            window.location.href = '/company/'+searchVal;
        }
    }
    
    return (
      <>
        <div className="topmenuc">
            <div className="container">
                <div className="menuitemc active" onClick={(e) => document.location='/'}>
                    <img src="/img/otclogo.png" alt="OTCSHORTREPORT.COM" />
                    <div>OTCSHORTREPORT.COM</div>
                </div>
                <div className="menuitemc" onClick={(e) => document.location='https://nakedshortreport.com'}>
                    <img src="/img/nsr_logo.png" alt="NAKEDSHORTREPORT.COM" />
                    <div>NAKEDSHORTREPORT.COM</div>
                </div>
                <div className="menuitemc"  onClick={(e) => document.location='https://shortdata.ca/'}>
                    <img src="/img/shortdata_logo.png" alt="SHORTDATA.CA" />
                    <div>CANADIAN SHORT DATA</div>
                </div>
            </div>	
        </div>	
        <div className="menuborder"></div>
        <div className="logoc">
            <div className="container">
                <div className="headerh">
                    <div className="logo" onClick={(e) => document.location='/'}>
                        <span className="logo1">OTC</span>
                        <span className="logo2">ShortReport</span>
                    </div>
                    <div className="searchc">
                        <form onSubmit={handleSubmit}>
                        <input type="text" placeholder="stock symbol" ref={searchRef} id="search" maxLength="10" autoComplete="off" />
                        <div id="searchsubmit" onClick={handleSubmit}></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="tickerc">
            <div className="container">
                <div className="th">Most Shorted OTC Stocks:</div>
                <div className="mostshortedc">
                {
                    mostShorted.map((mSR, index) => 
                        <div key={index} className={index == 0 ? 'ms msfirst' : 'ms'}>
                            <a href={`/company/${mSR}`}>{mSR}</a>
                        </div>
                    )
                }
                </div>
            </div>		
        </div>
      </>
    );
}
  
export default Header;