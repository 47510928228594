import Header from './Sections/Header';
import Footer from './Sections/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Faq from './Pages/Faq';
import Privacy from './Pages/Privacy';
import Disclaimer from './Pages/Disclaimer';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


function App() {
    return (
        <Router>
            <Header />
                <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/company/:cid">
                    <Home />
                </Route>
                <Route path="/about">
                    <About />
                </Route>
                <Route path="/faq">
                    <Faq />
                </Route>
                <Route path="/privacy-policy">
                    <Privacy />
                </Route>
                <Route path="/disclaimer">
                    <Disclaimer />
                </Route>
                <Route path="*">
                    <Home />
                </Route>
                </Switch>
            <Footer />
        </Router>

    );
}

export default App;