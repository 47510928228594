import Loading from '../Sections/Loading';
import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import Highcharts from 'highcharts';
import { useParams } from 'react-router';

function Home() {
    
    const { cid } = useParams();

    const subscribeBoxRef = useRef(null);

    /* Check param */
    let cSym = '';
    if(typeof cid !== 'undefined' ) cSym = cid.replace(/\s/g, "");
    cSym = cSym.substr(0, 10);

    const [histShortVol, sethistShortVol] = useState([]);
    const [companySym, setCompanySym] = useState('');
    
    
    useEffect(() => {
        
        async function loadCompany() {
            
            try {
                let cUrl = '/ajax.php?action=getcompanyinfo';
                if(cSym.length > 0) { cUrl += '&company='+cSym }
                //let cUrl = 'test.json';
                const response = await fetch(cUrl);
                const jsonObj = await response.json();
                setCompanySym(jsonObj.namets);
                
                if(jsonObj.xAxisArr.length > 0) {
                    var xAxisArr = jsonObj.xAxisArr;
                    var shortVolArr = jsonObj.shortVolArr;
                    var regularVolArr = jsonObj.regularVolArr;
                    
                    sethistShortVol(jsonObj.historicalShortVol);
                    
                    //console.log(histShortVol);
                    var chartProperties = {
                        chart:{type:'line'},title:{text:''},subtitle:{text:''},
                        xAxis:{categories:xAxisArr,labels:{rotation:-90,style:{fontSize:'10px'}}},
                        yAxis:{title:{text:''},labels:{formatter:function(){return this.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",");}}},
                        tooltip:{formatter:function(){var s='<b>'+this.x.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")+'</b>';
                        $.each(this.points,function(i,point){s+='<br/>'+point.series.name+': <b>'+point.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")+'</b>';});
                        return s;},shared: true},plotOptions:{area:{marker:{enabled:true,symbol:'circle',radius:4,states:{hover:{enabled:true}}}}},
                        exporting:{enabled:false},series: [{name: 'Regular Volume',data: regularVolArr,color:'#EC486B'}, {name: 'Short Volume',data: shortVolArr,color:'#A2A2A2'}]
                    };
                    
                    if($(window).width() <= 360) {
                        $('#htablec').css('max-width', $('.container').width()-10);
                        $('.htableh').css('width', $('#htable').width());
                    }
                    else {
                        $('#htablec').css('max-width', $('.midcontent').width()-10);
                        $('.htableh').css('width', 'auto');
                    }
                    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                        $('#htablec').css('max-width', $(window).width()-10);
                        $('.htableh').css('width', $('#htable').width());
                    }
                    $('#containergraph').html('');
                    setTimeout(function(){ 
                        var widthTemp = 0;
                        if($(window).width() <= 360) {
                            widthTemp = 345;
                        }
                        else if($(window).width() > 360 && $(window).width() <= 980){
                            widthTemp = $('.container').width() -10;
                        }
                        else {
                            widthTemp = ($('.container').width() * 99)/100;
                        }
                        Highcharts.chart('containergraph', chartProperties).setSize(widthTemp, "400");
                    }, 500);
                }

                var windowWidth = $(window).width();
                
                $(window).resize(function(){
                    if(jsonObj.xAxisArr.length > 0) {
                        if(windowWidth != $(window).width()) {
                            windowWidth = $(window).width();
                            if($(window).width() <= 360) {
                                $('#htablec').css('max-width', $('.container').width()-10);
                                $('.htableh').css('width', $('#htable').width());
                            }
                            else {
                                $('#htablec').css('max-width', $(window).width()-10);
                                $('.htableh').css('width', $('#htable').width());
                            }
                            
                            if($(window).width() <= 360) {
                                Highcharts.chart('containergraph', chartProperties).setSize(345, "400");
                            }
                            else if($(window).width() > 360 && $(window).width() <= 980){
                                Highcharts.chart('containergraph', chartProperties).setSize($('.container').width() -10, "400");
                            }
                            else {
                                Highcharts.chart('containergraph', chartProperties).setSize(($('.container').width() * 99)/100, "400");
                            }
                        }
                    }
                });
            }
            catch(e) {
                console.log(e);
            }
        }

        loadCompany();
    
    }, []);

    let cnwslbusy = 0;
    let cnwslfail = 0;
    let cemailnwslpreg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    function handleSubsciption(e) {
        e.preventDefault();
        if(cnwslbusy == 0) {
            cnwslbusy = 1;

            let icemailnewsletter = subscribeBoxRef.current.value.replace(/\s/g, "");
            if(icemailnewsletter == '') cnwslfail = 1;
	        if(cemailnwslpreg.test(icemailnewsletter) == false) cnwslfail = 1;

            if(cnwslfail) {
                cnwslbusy = 0;
                cnwslfail = 0;
                subscribeBoxRef.current.focus();
                alert('Please Enter Your Email Address Correctly!');
            }
            else {
                fetch('/newssnl.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: "action=subscribeandadd&semail="+icemailnewsletter                    
                })
                .then(res => res.text())
                .then(data => {
                    cnwslbusy = 0;
                    cnwslfail = 0;
                    subscribeBoxRef.current.value = '';
                    alert('Thank you for subscribing!');
                });
            }
        }        
    }

    return (
        <>
            <div className="container">
                <div className="mushroombigc" style={{textAlign: "center", cursor: "pointer", background: "white", border: "10px solid #850f2b"}} onClick={() => {
                            window.open("https://value-trades.blogspot.com/2024/12/blockmate-unambiguously-undervalued-crypto-stock.html?m=1", '_blank').focus();
                        }}>
                    <div className="mushroombigic">
                        <div className="mushroomtextsmallc" style={{width: "100%"}}>
                            <div className="mushroomtextsmallnc blink_me"><span style={{color: "green", fontWeight: "bolder"}}>SHORT SQUEEZE ALERT!!!</span></div><br />
                            <div className="mushroomtextsmallnc"><span style={{color: "green", fontWeight: "bolder"}}>TSX.V: MATE</span></div><br />
                            <div className="mushroomtextsmallnc"><span style={{color: "green", fontWeight: "bolder"}}>(Read The Report)</span></div>
                        </div>
                    </div>
                </div>
                <div className="mushroombigc">
                    <div className="mushroombigic">
                        <div className="mushroomtextsmallc">
                            <div className="mushroomtextsmallnc">Our next one drops soon. Be the first to know. Sign up Free Today!</div>
                            <div className="mushroomtextsmallnc"></div>
                        </div>
                        <div className="nwslmaincoptemailc">
                            <form onSubmit={handleSubsciption}>
                            <div className="nwslmaincoptemail"><input ref={subscribeBoxRef} type="text" id="icemailnewsletter" autoComplete="off" placeholder="your email address" maxLength="75" /></div>
                            <div className="nwslmaincoptjoin" id="nwslmaincoptjoinnwsl" onClick={handleSubsciption}>JOIN NOW!</div>
                            </form>
                        </div>
                        <div className="mushroomicsmalld">All good no spamming here just pure data and news</div>
                    </div>
                </div>
                <div className="midcontent">
                    <div className="leftc">
                        { companySym == '' && <Loading /> }    
                        { companySym !== '' &&
                        <div className="namec">
                            <div className="namets">({ companySym })</div>
                        </div>
                        }
                        { histShortVol.length > 0 && companySym !== '' &&
                            <div className="graphc">
                                <div className="tabc">
                                    <div id="tab1"className="activetab">Naked Short Interest</div>
                                </div>
                                <div className="graphinnerc">
                                    <div className="svrc">
                                        <div className="svr">{ companySym }</div>
                                        <div className="svrr">
                                            <div className="svrrt">- Short Volume Report&nbsp;&nbsp;(REGSHO)</div>
                                            <div className="svrrb">All RegSho Dated reported by: FINRA</div>
                                        </div>
                                    </div>
                                    <div id="containergraph"></div>
                                </div>
                            </div>
                        }
                        { histShortVol.length == 0 && companySym !== '' &&
                            <div className="namec">
                                <div className="namet">No Information Found!</div>
                            </div>
                        }
                        { histShortVol.length > 0 && companySym !== '' &&
                            <div id="htablec">
                                <div className="htableh">
                                    <span id="tableheading">Historical Short Volume Data for</span> { companySym }
                                </div>
                                <table id="htable">
                                    <thead>
                                        <tr id="theadc">
                                            <th>Date</th>
                                            <th>Close</th>
                                            <th>High</th>
                                            <th>Low</th>
                                            <th>Volume</th>
                                            <th className="tc">Short Volume</th>
                                            <th className="tc">% of Vol Shorted</th>
                                        </tr>
                                    </thead>
                                    <tbody id="htabletr" className="htbltr">
                                    {
                                        histShortVol.map((hSV, index) => 
                                            <tr key={index}>
                                                <td>{hSV[0]}</td>
                                                <td>{hSV[4]}</td>
                                                <td>{hSV[2]}</td>
                                                <td>{hSV[3]}</td>
                                                <td>{hSV[5]}</td>
                                                <td align="center">{hSV[7]}</td>
                                                <td align="center">{hSV[8]}</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }
                            <br /><br /><br /><br /><br />
                        </div>
                </div>
          </div>
        </>
    );
}
  
export default Home;